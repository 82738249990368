<template>

<div class="mobile-page-layout mobile-TradingView" style="height: 100%;">
    <div class="mobile-page-header">
        <div class="mobile-page-header-row">
            <div class="mobile-page-header-right">
                <router-link class="mobile-page-header-icon pointer back-icon" :to="{ name: 'NewTechnical' }" >
                    <i class="header-uil-icon uil uil-angle-right-b"></i>
                </router-link>
                <div class="mobile-page-header-icon">
                    <div class="mobile-page-header-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="header-svg-icon">
                            <path d="M160 320c53.02 0 96-42.98 96-96c0-53.02-42.98-96-96-96C106.1 128 64 170.1 64 224C64 277 106.1 320 160 320zM192 352H128c-70.69 0-128 57.3-128 128c0 17.67 14.33 32 32 32h256c17.67 0 32-14.33 32-32C320 409.3 262.7 352 192 352z" class="fa-primary"></path>
                            <path d="M592 0h-384C181.5 0 160 22.25 160 49.63V96c23.42 0 45.1 6.781 64 17.8V64h352v288H287.3c22.07 16.48 39.54 38.5 50.76 64h253.9C618.5 416 640 393.8 640 366.4V49.63C640 22.25 618.5 0 592 0zM312 218l55.01 54.99c9.375 9.375 24.6 9.375 33.98 0L473 201l24.25 24.25C508.6 236.6 528 228.6 528 212.5V124c0-6.625-5.375-12-12-12h-88.5c-16.12 0-24.12 19.38-12.75 30.75L439 167L384 222l-55.01-55.01c-9.375-9.375-24.6-9.375-33.98 0L280.3 181.8C285 195.1 288 209.1 288 224c0 6.875-.9999 13.38-2 20L312 218z" class="fa-secondary"></path>
                        </svg>                        
                    </div>
                </div>
                <div class="mobile-page-header-text">افزودن تحلیل</div>  
            </div>
            <div class="mobile-page-header-actions">

            </div>
        </div>
    </div>
    <form @submit.prevent="" style="height: calc(100% - 50px);">
        <slot v-if="widgetLoad">
            <div class="text-center p-2">
                <div class="widget-loading"></div>
            </div>
        </slot>
        <slot v-else>
            <TradingView :widget="widget" :data="widgetData" :editable="true" />
        </slot>
    </form>
</div>
</template>

<style lang="scss" scoped>

    #widgets-modal .modal-content,
    .modal[role="dialog"] .modal-content {
        max-width: 100% !important;
    }
</style>

<script>
// این کامپوننت مودال افزودن تحلیل جدید می باشد
import TradingView from '@/components/TradingView.vue'
export default {
    name: 'AddTechnical',
    components: {
        TradingView
    },
    data: function () {
        return {
            widget: {},
            widgetData: {},
            widgetLoad: true,
        }
    },
    mounted() {
        window.addEventListener('message', this.receiveMessage)
        this.$helpers.getWidgets({ widget_type: 'trading-view', store: false }).then((widgets) => {
            let widget = widgets[0];
            this.widgetLoad = false;

            this.widget = widget.widget;
            this.widgetData = widget.data;
        });
    },
    methods: {
        receiveMessage (event) {
            if (event.data == "close") {
                this.$router.push({name: 'NewTechnical'} );
            } else if (event.data == "diagram") {
                this.openDiagram();
            } else if (event.data == "indicator") {
                this.openIndicator();
            }
        },
        // این متد جهت باز کردن مودال شاخصیاب استفاده میشود
        openDiagram() {
            let callbackEvent = 'jQuery.hColumns' + new Date().getTime();
            localStorage.setItem('jQuery_hColumns_event', callbackEvent);

            this.$root.$on(callbackEvent, (data) => {
                this.$root.$off(callbackEvent);
                $('.tv-iframe-wrapper').html(`<iframe id="tv-iframe" src="${this.$helpers.tv4_url()}/?symbol=${data.symbol}&item_id=${data.item_id}" style="border:none;width: 100%;display: inline-block;height: 750px;"></iframe>`);
            });

            $('#indicator-modal').show();
            $('#indicator-modal #columns2').html('');
            this.$helpers.diagrams_render();
        },
        // این متد جهت فول اسکرین کردن تریدینگ ویو استفاده میشود
        fullScreen(event) {
            // $(event).target.parents().eq(3).find('.iframe-wrapper-box').toggleClass('fullscreen-trading'); 
            $(".dashboard-widget-tabs").toggleClass('fullscreen-trading');
        },
    },
}
</script>
